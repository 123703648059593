/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, isRef as _isRef, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import InputNumber from 'primevue/inputnumber';
export default {
  __name: 'MNumberInput',
  props: {
    fz: String,
    modelValue: {
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    isCeil: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var inputValue = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(value) {
        if (props.isCeil) {
          value = Math.ceil(value) || 1;
        }

        emit('update:modelValue', value);
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(InputNumber), {
        class: "m-number-input",
        showButtons: "",
        buttonLayout: "horizontal",
        incrementButtonIcon: "pi pi-plus",
        decrementButtonIcon: "pi pi-minus",
        min: props.min,
        modelValue: _unref(inputValue),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(inputValue) ? inputValue.value = $event : null;
        }),
        style: _normalizeStyle({
          '--base-input-font-size': __props.fz || null
        })
      }, null, 8, ["min", "modelValue", "style"]);
    };
  }
};